import React from 'react';
import './App.css';

// Track record data
const trackRecordItems = [
  {
    token: '$GNON',
    entry: '$12.8M mcap',
    peak: '$389M mcap',
    multiplier: '30.4x'
  },
  {
    token: 'PROJECT89',
    entry: '$10M mcap',
    peak: '$122M mcap',
    multiplier: '12.2x'
  },
  {
    token: '$AIXBT',
    entry: '$5M mcap',
    peak: '$22M mcap',
    multiplier: '4.4x'
  }
];

function App() {
  return (
    <div className="App">
      <header className="hero">
        <div className="fea">
          <div className="hero-content">
            <span className="badge">In Crypto Since 2017</span>
            <h1>SATO<span className="accent">SPEK</span></h1>
            <p className="hero-text">
              Delivering actionable insights into AI agents and decentralized AI within crypto markets.
            </p>
            <p className="subtitle">Empowering VCs, PEs, and Family Offices with cutting-edge research and market intelligence.</p>
          </div>
        </div>
      </header>

      <section className="track-record">
        <span className="badge">Track Record</span>
        <div className="track-record-grid">
          {trackRecordItems.map((item, index) => (
            <div key={index} className="record-card">
              <h3 className="accent">{item.token}</h3>
              <div className="multiplier accent">{item.multiplier}</div>
              <div className="record-details">
                <p>Entry: {item.entry}</p>
                <p className="accent">Peak: {item.peak}</p>
              </div>
            </div>
          ))}
        </div>
      </section>

      <section className="our-edge">
        <span className="badge">Why Choose Us</span>
        <div className="edge-grid features-grid">
          <div className="edge-card feature-card">
            <h3 className="accent">Specialized Focus</h3>
            <p className="description">
              We are experts in analyzing AI agents and decentralized AI within the crypto ecosystem, offering unique insights tailored to your investment needs.
            </p>
          </div>
          <div className="edge-card feature-card">
            <h3 className="accent">Proven Results</h3>
            <p className="description">
              Our track record demonstrates our ability to identify early-stage opportunities with exponential growth potential, ensuring you stay ahead of market trends.
            </p>
          </div>
          <div className="edge-card feature-card">
            <h3 className="accent">Research-Driven Strategy</h3>
            <p className="description">
              Combining proprietary frameworks and rigorous analysis, we deliver actionable intelligence to maximize your investment returns.
            </p>
          </div>
        </div>
      </section>

      <section className="cta-section">
        <h2>Access Exclusive Research</h2>
        <p className="subtitle">
          Join the leading investors leveraging our insights to capitalize on AI agents and decentralized AI opportunities in crypto.
        </p>
        <button className="cta-button primary large">Request Access</button>
      </section>

      <footer>
        <p>© 2024 SATOSPEK. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default App;

